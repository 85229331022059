<template>
  <v-container>
    <v-navigation-drawer app dark color="primary">
      
      <v-list>
        <v-list-group v-for="item in menus" :key="item.title" color="white">
          <template #prependIcon>
            <v-icon class="mx-0 px-0">
              {{ item.icon }}
            </v-icon>
          </template>
          <template #activator>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </template>

          <v-list-item
            v-for="child in item.submenus"
            :key="child.name"
            :to="child.to"
            link
          >
            <v-list-item-title v-text="child.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      countryCode:'',
      
      location: null,
      apiResponse: {},
      sidebar: true,
      menus: [
        {
          name: "Listing",
          icon: "mdi-cash",
          submenus: [
            {
              name: "Update Listing Profile",
              icon: "mdi-account-edit",
              to: "/teacher/manage/update-listing",
            },
            {
              name: "Bookings",
              icon: "mdi-account-edit",
              to: "/teacher/manage/bookings",
            },
            {
              name: "Student Lists",
              icon: "mdi-account-edit",
              to: "/teacher/manage/bookingStudent",
            },
            {
              name: "Attendance Lists",
              icon: "mdi-account-edit",
              to: "/teacher/manage/attendance",
            },
          ],
        },
        {
          name: "Notice",
          icon: "mdi-note-outline",
          submenus: [
            {
              name: "notice",
              icon: "mdi-note-outline",
              to: "/teacher/manage/noticeTeacher",
            },
          ],
        },
        {
          name: "Examboard",
          icon: " mdi-bulletin-board",
          submenus: [
            {
              name: "Examboard",
              icon: "mdi-account-edit",
              to: "/teacher/manage/examboardTeacher",
            },
          ],
        },
        {
          name: "Attendance",
          icon: "  mdi-alarm-panel-outline",
          submenus: [
            {
              to: "/teacher/manage/attendance/show/",
              name: "Show Attendance",
              component: () =>
                import("@/pages/organization/attendance/ShowAttend.vue"),
            },
            {
              to: "/teacher/manage/attendance/showDetail/",
              name: "Attendance Detail",
              component: () =>
                import("@/pages/organization/attendance/ShowAttendDetail.vue"),
            },
          ],
        },

      ],
    };
  },
  mounted() {
   
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;
          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              this.location = {
                latitude,
                longitude,
                country: data.address.country,
              };
              this.fetchCountryCode();
            });
        },
        (error) => {
          console.error(error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }

    
    
    // this.fetchCountryCode();
    // this.fetchSMSSettings();
  },
  methods: {
    
  fetchCountryCode() {
  this.$api
    .get("/country_code/")
    .then((response) => {
      this.apiResponse = response.data.results;
      // console.log("location " + this.location.country);
      const matchingCountries = this.apiResponse.filter(
        (c) => c.name === this.location.country && c.allow_sms
      );
      if (matchingCountries.length > 0) {
        this.menus.push({
          name: "Sms",
          icon: "mdi-message",
          submenus: [
            {
              name: "Compose",
              icon: "mdi-bulletin-board",
              to: "/sms/compose/",
            },
            {
              name: "Draft",
              icon: "mdi-message-text",
              to: "/sms/draft/",
            },
            {
              name: "Template",
              icon: "mdi-bulletin-board",
              to: "/sms/template/",
            },
            {
              name: "Schedule SMS",
              icon: "mdi-bulletin-board",
              to: "/sms/schedule/",
            },
            {
              name: "Buy SMS",
              icon: "mdi-bulletin-board",
              to: "/sms/buySms/",
            },
            {
              name: "SMS Report",
              icon: "mdi-bulletin-board",
              to: "/sms/list/",
            },
            // {
            //   name: "SMS Rules",
            //   icon: "mdi-bulletin-board",
            //   to: "/sms/rules/",
            // },
          ],
        });
      }
    })
    .catch((error) => {
      console.error(error);
    });
},



  //   fetchSMSSettings() {

      
  //       this.$api.get('/sms_setting/')
  //     .then(response => {
  //       const settings = response.data.results;
  //       if (settings.length > 0) {
  //         const showMenu = settings[0].show_menu;
  //         const smsMenuIndex = this.menus.findIndex(item => item.name === 'Sms');
  //         if (smsMenuIndex !== -1) {
  //           if (!showMenu) {
  //             this.menus.splice(smsMenuIndex, 1);
  //           }
  //         } else {
  //           if (showMenu) {
  //             this.menus.push(
  //               {
  //               name: "Sms",
  //               icon: "mdi-message",
  //               submenus: [
  //                 {
  //                   name: "Compose",
  //                   icon: "mdi-bulletin-board",
  //                   to: "/sms/compose/",
  //                 },
  //                 {
  //                   name: "Draft",
  //                   icon: "mdi-message-text",
  //                   to: "/sms/draft/",
  //                 },
  //                 {
  //                   name: "Template",
  //                   icon: "mdi-bulletin-board",
  //                   to: "/sms/template/",
  //                 },
  //                 {
  //                   name: "Schedule SMS",
  //                   icon: "mdi-bulletin-board",
  //                   to: "/sms/schedule/",
  //                 },
  //                 {
  //                   name: "Buy SMS",
  //                   icon: "mdi-bulletin-board",
  //                   to: "/sms/buySms/",
  //                 },
  //                 {
  //                   name: "SMS Report",
  //                   icon: "mdi-bulletin-board",
  //                   to: "/sms/list/",
  //                 },
  //                 {
  //                   name: "SMS Rules",
  //                   icon: "mdi-bulletin-board",
  //                   to: "/sms/rules/",
  //                 },
  //               ],
  //             });
  //           }
  //         }
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });

      
   
  // },
  }
};
</script>

<style>
</style>
